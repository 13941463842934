<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Plot'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/vehicle`" title="Vehicle" :columns="columns"
                routerpath="/master/crm/addvehicle" :formOptions="formOptions" :newPage="true" :edit="edit" :add="add"
                :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";

import moment from "moment";

import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        submitRouterPath: "/master/crm/vehicle",
        formTitle: "Add Vehicle",
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/vehicle`,
        getEditValue: `${baseApi}/getvehicleById`,
        inputFields: [],
      },
      viewFields: [
        {
          label: 'Vehicle Name',
          field: 'vehiclename',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Vehicle No.',
          field: 'vehicleno',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Vehicle Colour',
          field: 'vehiclecolour',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Fuel Type',
          field: 'fueltype',
          type: 'text',
          class: 'col-md-4'
        }, {
          label: 'Vehicle Type',
          field: 'vehicletype',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Insurance Issue Date',
          field: 'insurancestartdate',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Insurance End Date',
          field: 'insuranceenddate',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'PUC Issue Date',
          field: 'pucstartdate',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'PUC End Date',
          field: 'pucenddate',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'PUC Photo',
          field: 'pucphoto',
          type: 'attachment',
          class: 'col-md-4'
        },
        {
          label: "RC Book Front Side",
          field: 'rcbookphoto',
          type: 'fieldInObj',
          childField: 'front',
          childFieldType: 'attachment',
          class: "col-md-4",
        },
        {
          label: "RC Book Front Side",
          field: 'rcbookphoto',
          type: 'fieldInObj',
          childField: 'back',
          childFieldType: 'attachment',
          class: "col-md-4",
        },
      ],
      columns: [
        {
          label: "Name",
          field: "vehiclename",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Vehicle No",
          field: "vehicleno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Vehical No",
          },
        },
        {
          label: "Vehicle Type",
          field: "vehicletype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Vehical Type",
          },
        },
        {
          label: "Fuel Type",
          field: "fueltype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Vehical Type",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          url: 'getUsers',
          responseValue: 'name',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          url: 'getUsers',
          responseValue: 'name',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Vehicle") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
